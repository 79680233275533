<template>
  <div class="maxHeight">
    <div class="header flex">
      <div class="title flex">
        <img src="../../../assets/img/ex_c.png" alt="" width="28">
        <h2>课题详情</h2>
      </div>
      <el-button size="small" @click="$router.go(-1)">返回上一页</el-button>
    </div>
    <div class="activeInfo">
      <span style="font-weight: bolder">课题编号：{{ activeInfo.number }}</span>
      <span v-if="activeInfo.detail">项目负责人：{{ activeInfo.detail.director }}</span>
      <span>计划开始时间：{{ activeInfo.startTime | formatDay }}</span>
      <span>动物品系：{{ activeInfo.strain }}</span>
      <span>课题名称：{{ activeInfo.name }}</span>
    </div>
    <div class="maxWidth">
      <div class="step commonClass">
        <template v-for="(item,ind) in stepList">
          <el-button
              :disabled="item.disabled"
              type="text" size="small" class="stepList" :key="ind"
              @click="active=ind"
              v-if="(activeInfo.isNotApproval==0&&ind==1) ||  (activeInfo.isLabAni==0&&(ind == 2 || ind == 3 || ind == 4)) || (4<ind||ind==0)">
            <span class="tit" :class="[{activeClass:active == ind},{beforeClass:ind<active }]">{{ item.name }}</span>
            <i class="el-icon-arrow-down" v-show="ind!==stepList.length-1"></i>
          </el-button>
        </template>
      </div>
      <!--    方案详情-->
      <detailSub v-if="active==0" :id="id"></detailSub>
      <!--    方案审批-->
      <approval v-else-if="active==1" :id="id"></approval>
      <!--    动物请购-->
      <taskPurchase v-else-if="active==2" :id="id" :status="status"></taskPurchase>

      <!--    动物接收-->
      <taskReceive v-else-if="active==3&&flag == true" :id="id" @changeStatus="changeStatus"
                   :status='status'></taskReceive>
      <!-- 动物接收   导出预览-->
      <receivePreview v-else-if="active==3&&flag == false" :id="id" @changeStatus="changeStatus"></receivePreview>

      <!--    检疫-->
      <quarantine v-else-if="active==4&&flagQ == true" :id="id" :status='status'
                  @changeStatus="changeStatus"></quarantine>
      <!--    检疫 导出预览-->
      <quarantineFinish v-else-if="active==4&&flagQ == false" :id="id" @changeStatus="changeStatus"></quarantineFinish>

      <!--    申领-->
      <claims v-else-if="active==5" :id="id" :activeInfo="activeInfo.aniOrder" :status="status"></claims>

      <!--    合笼-->
      <!--                  <point v-else-if="active==6&&flagP == true" :activeInfo="activeInfo"></point>-->
      <ensemble v-else-if="active==6&&flagP == true" :activeInfo="activeInfo"></ensemble>
      <!--      合笼提交后-->
      <pointFinish v-else-if="active==6&&flagP == false" :activeInfo="activeInfo"></pointFinish>


      <!--    合笼情况-->
      <inCage v-else-if="active==7" :id="id" :isLabAni="activeInfo.isLabAni" :isProd="activeInfo.isProd"></inCage>

      <!--    新生动物-->
      <newAnimal v-else-if="active==8" :id="id" @changeActive="changeActive"></newAnimal>

      <!--    终点-->
      <end v-else-if="active==9" :id="id" :isLabAni="isLabAni"></end>
    </div>

    <div style="clear: both"></div>
  </div>

</template>

<script>

export default {
  name: "taskDetail",
  data() {
    return {
      activeInfo: {},
      status: null,
      id: this.$route.query.id,
      stepList: [
        {disabled: true, name: '实验方案'},
        {disabled: true, name: '方案审批'},
        {disabled: true, name: '动物请购'},
        {disabled: true, name: '动物接收'},
        {disabled: true, name: '动物检疫'},
        {disabled: true, name: '动物申领'},
        {disabled: true, name: '动物合笼'},
        {disabled: true, name: '合笼情况'},
        {disabled: true, name: '新生动物'},
        {disabled: true, name: '合笼终点'},
      ],
      active: 0,
      flag: true,
      flagQ: true,
      flagP: true,
      disabled: false,
      isLabAni: null
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      let that = this;
      that.$get("/subject/detail/" + this.id).then((res) => {
        if (res.status == 200) {
          that.activeInfo = res.data
          that.isLabAni = res.data.isLabAni
          that.status = res.data.status
          if (this.status == 0 || this.status == 1 || this.status == 2) {
            this.active = 0
          } else if (this.status == 3 || this.status == 4) {
            this.active = 1
          } else if (this.status == 5 || this.status == 6) {
            this.active = 2
          } else if (this.status == 8 || this.status == 9) {
            this.active = 3
          } else if (this.status == 10 || this.status == 11 || this.status == 12 || this.status == 14) {
            this.active = 4
          } else if (this.status == 15 || this.status == 16) {
            this.active = 5
          } else if (this.status == 30) {
            this.active = 6
          } else if (this.status == 31) {
            this.active = 7
            this.flagP = false
          } else if (this.status == 33) {
            this.active = 7
            this.stepList.forEach(item => {
              if (item.name == '新生动物') {
                item.disabled = false
              }
            })
          } else if (this.status == 35) {
            this.active = 8
          } else if (this.status == 38 || this.status == 39 || this.status == 40) {
            this.active = 9
          }
          if (this.active > 6) {
            this.flagP = false
          }
          this.stepList.forEach((item, index) => {
            if (this.active >= index) {
              item.disabled = false
            }
          })
        }
      }).catch(() => {
        this.$message.error('获取数据失败')
      })
    },
    changeStatus(data, state) {
      if (state == 0) {
        this.flag = data
      } else {
        this.flagQ = data
      }
    },
    changeActive() {
      if (this.status == 38 || this.status == 39 || this.status == 40) {
        this.active = 9
      }
      location.reload()
    }
  },

  components: {
    // 详情
    "detailSub": () => import("./process/detailSub"),
    // 审批
    "approval": () => import("../exper/process/approval"),
    // 动物请购
    "taskPurchase": () => import("./process/taskPurchase"),
    // 动物接收
    "taskReceive": () => import("./process/taskReceive"),
    // 动物接收--导出
    "receivePreview": () => import("./process/receivePreview"),
    // 动物检疫
    "quarantine": () => import("./process/quarantine"),
    // 动物检疫--导出
    "quarantineFinish": () => import("./process/quarantineFinish"),
    // 动物申领
    "claims": () => import("./process/claims"),
    // 动物合笼
    "point": () => import("./process/point"),
    // 动物合笼
    "ensemble": () => import("./process/ensemble"),
    // 动物合笼
    "pointFinish": () => import("./process/pointFinish"),
    // 合笼情况
    "inCage": () => import("./process/inCage"),
    // 新生动物
    "newAnimal": () => import("./process/newAnimal"),
    // 合笼终点
    "end": () => import("./process/end"),
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/exper/experiDetail";
</style>
